<script>
import { FormModel } from '@/components/global/form';
import { scrollToError } from '../../utilities/helpers';
import swal from 'sweetalert';

export default {
    name: 'CcRegistrationFrom',

    data() {
        return {
            loading: false,
            formSent: false,
            model: new FormModel({
                name: null,
                last_name: null,
                email: null,
                credit_union_id: null,
                personal_code: null,
                address: null,
                phone: null,
                is_working: true,
                is_studying: false,
                workplace_title: null,
                workplace_address: null,
                school_title: null,
                school_address: null,
                personal_data_agreement: false,
                marketing_agreement: null,
                marketing_communication_tools: [],
                marketing_communication_types: [],
                updatable: true,
            }),
            disable_credit_union_select: false,
            meta: {
                credit_unions: [],
                marketing_communication_tools: [],
                marketing_communication_type: [],
            },
        };
    },

    created() {
        this.load();
        this.$root.$on('next-step', this.submit);
    },

    computed: {
        creditUnion() {
            return this.meta.credit_unions.find(item => item.id === this.model.$.credit_union_id);
        },
    },

    watch: {
        'model.$.personal_code'() {
            this.model.$.personal_code = this.model.$.personal_code.replace(/\D/g, '');
        },

        'model.$.phone'() {
            this.model.$.phone = this.model.$.phone.replace(/\D/g, '');
        },
    },

    methods: {
        showAlert(notice) {
            if (notice) {
                swal({
                    title: 'Dėmesio!',
                    text: notice,
                    icon: 'info',
                    button: {
                        text: 'Supratau',
                        className: 'btn btn-primary',
                    },
                });
            }
        },
        load() {
            this.$http.get('/api/credit-unions/source').then(response => {
                this.meta.credit_unions = response.data.data || [];
                const parsedUrl = new URL(window.location.href);
                const unionId = parsedUrl.searchParams.get('union_id');
                if (unionId) {
                    const union = this.meta.credit_unions.find(item => item.id === parseInt(unionId));
                    if (union && !this.model.$.credit_union_id) {
                        this.model.$.credit_union_id = union.id;
                        this.disable_credit_union_select = true;
                    }
                }
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.data && error.response.data.data.redirect) {
                    window.location = error.response.data.data.redirect;
                }
            });

            this.$http.get('/api/membership-request').then(response => {
                this.model.$ = { ...this.model.$, ...response.data.data };
                if (response.data.data) {
                    this.disable_credit_union_select = false;
                }
            });

            this.$http.get('/api/marketing-communication-type/source').then(response => {
                this.meta.marketing_communication_types = response.data.data || [];
            });

            this.$http.get('/api/marketing-communication-tool/source').then(response => {
                this.meta.marketing_communication_tools = response.data.data || [];
            });
        },

        async submit() {
            if (this.loading) {
                return;
            }
            this.loading = true;

            try {
                const response = await this.$http.post('/api/register', this.model.$);
                window.location = response.data.data.redirect;
            } catch (e) {
                this.model.$e.updateFromResponse(e);
                scrollToError();
                this.$sentyReporting.report(e);
            }

            this.loading = false;
        },
    },
};
</script>
